import React from "react";
import Chart from "../components/Chart";
import { useLocation } from 'react-router-dom';

const Dashboard = () => {
  /* token and headers*/
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hmac = queryParams.get('hmac');
  if(hmac!==null){
    window.location.href='/change-password?hmac=' + hmac;
  } else {
    const token = localStorage.getItem('accessToken');
    if(token === '' || token === undefined || token === null){
      localStorage.setItem('accessToken', '');
      localStorage.setItem('menuAccess', []);
      localStorage.setItem('profileRole', '');
      window.location.href = '/login';
    } 
  }
  
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <div className="d-flex align-items-end row">
                <div className="col-sm-10">
                  <div className="card-body">
                    <h5 className="card-title text-primary">Welcome {localStorage.getItem('profileName')}! 🎉</h5>
                    <p className="mb-4">
                    The <b>GyftHint Retail Interface Platform (GRIP<sup>TM</sup>)</b> provides you with secure access and tools to manage your account with GyftHint, including:
                    <ol>
                      <li>Viewing and updating portal licenses and users</li>
                      <li>Viewing and updating Suggested Products (a unique feature to your relationship with GyftHint - these products will be added as a Suggested Hint to your customers when a customer first clicks on the Add to GyftHint universal wishlist button on your site),</li>
                      <li>Viewing product activity and conversion for your brand through GyftHint:</li>
                        <ul>
                          <li>Hints - are products added to individual user stores for purchase by their network of buyers or themselves</li>
                          <li>Orders - are completed purchases made by buyers from your customers' stores</li>
                          <li>Commissions - are details on the affiliate commissions payable for those referred orders</li>
                        </ul>
                      <li>Understanding your value received from the platform through dashboard analytics and easy to understand performance metrics</li>
                      <li>Promotion capability (coming soon) - with certain license upgrades, being provided with the ability to drive unique promotions on your products within the personal stores on GyftHint, enabling you to highlight and differentiate your products to influence buyer behaviors and conversion</li>
                      <li>Data as a Service (DaaS) offering (coming soon) - providing detailed industry and trending product and category analytics and brand performance on GyftHint</li>
                    </ol> 
                    </p>
                    {localStorage.getItem('profileRole').toLowerCase()  == 'merchant' && 
                      <a className="btn btn-sm btn-primary" href={'/suggested-hints'}>Update Your Suggested Products</a>
                      }
                  </div>
                </div>
                <div className="col-sm-2 text-center text-sm-left">
                  <div className="card-body pb-0 px-0 px-md-4">
                    <img
                      src="../assets/img/illustrations/man-with-laptop-light.png"
                      height="140"
                      alt="View Badge User"
                      data-app-dark-img="illustrations/man-with-laptop-dark.png"
                      data-app-light-img="illustrations/man-with-laptop-light.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 order-1">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-6 mb-4">
                <div className="card col-card-top-dash">
                  <div className="card-body">
                    <div className="card-title d-flex align-items-start justify-content-between">
                      <div className="avatar flex-shrink-0">
                        <i className="menu-icon-page tf-icons bx bx-gift rounded"></i>
                      </div>
                      <div className="dropdown">
                        <button
                          className="btn p-0"
                          type="button"
                          id="cardOpt3"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt3">
                          <a className="dropdown-item" href="">View More</a>
                          <a className="dropdown-item" href="">Delete</a>
                        </div>
                      </div>
                    </div>
                    <span className="fw-medium d-block mb-1">Products</span>
                    <h3 className="card-title mb-2">3,980</h3>
                    <small className="text-success fw-medium"><i className="bx bx-up-arrow-alt"></i> 28</small>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-6 mb-4">
                <div className="card col-card-top-dash">
                  <div className="card-body">
                    <div className="card-title d-flex align-items-start justify-content-between">
                      <div className="avatar flex-shrink-0">
                        <i className="menu-icon-page tf-icons bx bx-package "></i>
                      </div>
                      <div className="dropdown">
                        <button
                          className="btn p-0"
                          type="button"
                          id="cardOpt3"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt3">
                          <a className="dropdown-item" href="">View More</a>
                          <a className="dropdown-item" href="">Delete</a>
                        </div>
                      </div>
                    </div>
                    <span className="fw-medium d-block mb-1">Orders</span>
                    <h3 className="card-title mb-2">2,653</h3>
                    <small className="text-success fw-medium"><i className="bx bx-up-arrow-alt"></i> 21</small><br/><br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 col-lg-4 order-3 order-md-2">
            <div className="row">
              <div className="col-6 mb-4">
                <div className="card col-card-top-dash">
                  <div className="card-body">
                    <div className="card-title d-flex align-items-start justify-content-between">
                      <div className="avatar flex-shrink-0">
                        <i className='menu-icon-page tf-icons bx bx-purchase-tag-alt'></i>
                      </div>
                      <div className="dropdown">
                        <button
                          className="btn p-0"
                          type="button"
                          id="cardOpt4"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt4">
                          <a className="dropdown-item" href="">View More</a>
                          <a className="dropdown-item" href="">Delete</a>
                        </div>
                      </div>
                    </div>
                    <span className="fw-medium d-block mb-1">(AOV) Average Order Value</span>
                    <h3 className="card-title text-nowrap mb-2">$65.00</h3>
                    <small className="text-danger fw-medium"><i className="bx bx-down-arrow-alt"></i> 2 </small>
                  </div>
                </div>
              </div>
              
              <div className="col-6 mb-4">
                <div className="card col-card-top-dash">
                  <div className="card-body">
                    <div className="card-title d-flex align-items-start justify-content-between">
                      <div className="avatar flex-shrink-0">
                        <i className='menu-icon-page tf-icons bx bx-share'></i>
                      </div>
                      <div className="dropdown">
                        <button
                          className="btn p-0"
                          type="button"
                          id="cardOpt4"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt4">
                          <a className="dropdown-item" href="">View More</a>
                          <a className="dropdown-item" href="">Delete</a>
                        </div>
                      </div>
                    </div>
                    <span className="fw-medium d-block mb-1">Returns</span>
                    <h3 className="card-title text-nowrap mb-2">1.3%</h3>
                    <small className="text-danger fw-medium"><i className="bx bx-down-arrow-alt"></i> 2 </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-12 col-md-8 col-lg-4 order-3 order-md-2">
            <div className="row">
              
            <div className="col-6 mb-4">
                <div className="card col-card-top-dash">
                  <div className="card-body">
                    <div className="card-title d-flex align-items-start justify-content-between">
                      <div className="avatar flex-shrink-0">
                        <i className="menu-icon-page tf-icons bx bx-money "></i>
                      </div>
                      <div className="dropdown">
                        <button
                          className="btn p-0"
                          type="button"
                          id="cardOpt1"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <i className="bx bx-dots-vertical-rounded"></i>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="cardOpt1">
                          <a className="dropdown-item" href="">View More</a>
                          <a className="dropdown-item" href="">Delete</a>
                        </div>
                      </div>
                    </div>
                    <span className="fw-medium d-block mb-1">Commissions</span>
                    <h3 className="card-title mb-2">$180.00</h3>
                    <small className="text-success fw-medium"><i className="bx bx-up-arrow-alt"></i> 15%</small><br/><br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
            <div className="col-12 col-lg-6 d-none">
                <div className="top-pages col-dash-card">
                    <h2>Orders</h2>
                    <table>
                    <thead>
                        <tr>
                        <th>Page Path</th>
                        <th>Page Views</th>
                        <th>Sessions</th>
                        <th>New Users</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>/home</td>
                        <td>10,653</td>
                        <td>7,456</td>
                        <td>2,456</td>
                        </tr>
                        <tr>
                        <td>/shop</td>
                        <td>10,273</td>
                        <td>7,364</td>
                        <td>2,164</td>
                        </tr>
                        <tr>
                        <td>/catalog</td>
                        <td>9,367</td>
                        <td>6,853</td>
                        <td>1,953</td>
                        </tr>
                        <tr>
                        <td>/about</td>
                        <td>9,157</td>
                        <td>6,273</td>
                        <td>1,573</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div> 
        <div className="row">
          <div className="col-lg-12">
              <div className="rates-chart col-dash-card">
                <h3>Products & Orders By Month</h3>
                <Chart />
              </div>
          </div>
        </div>
      </div>
  )
}

export default Dashboard