import { useState } from "react"
import axios from "../api/axios";
import { useLocation } from 'react-router-dom';
//import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isOnProcess, setIsOnProcess]=useState(false);
  //const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hmac = queryParams.get('hmac');
  console.log(hmac);

  if(hmac!==null){
    window.location.href='/change-password?hmac=' + hmac;
  }

  const handleLogin = async(event) => {
    event.preventDefault();
    setIsOnProcess(true);
    try{
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/login', {email, password}).then(result =>{
          setEmail("");
          setPassword("");
          localStorage.setItem('accessToken', result.data.token);
          localStorage.setItem('userId', result.data.user_id);
          localStorage.setItem('id', result.data.id);
          localStorage.setItem('profileRole', result.data.menu_access[0]['role_name']);
          localStorage.setItem('menuAccess', JSON.stringify(result.data.menu_access));
          localStorage.setItem('currentModule', 'Dashboard');
          localStorage.setItem('stores', JSON.stringify(result.data.stores));
          localStorage.setItem('role', result.data.role);
          console.log('Store Details');
          console.log(result.data.stores);
          if(result.data.stores.length > 0 && result.data.role.toLowerCase()!=='admin'){
            localStorage.setItem('currentActiveStoreId', result.data.stores[0].uuid);
            localStorage.setItem('currentActiveStoreLicense', result.data.stores[0].label);
            localStorage.setItem('currentActiveStore', result.data.stores[0].store_name);
            localStorage.setItem('currentActiveStoreLogo', result.data.stores[0].store_logo);
            localStorage.setItem('currentActiveStoreDomain', result.data.stores[0].myshopify_domain);
          } else {
            localStorage.setItem('currentActiveStoreId', '');
            localStorage.setItem('currentActiveStoreLicense', '');
            localStorage.setItem('currentActiveStore', null);
            localStorage.setItem('currentActiveStoreLogo', null);
            localStorage.setItem('currentActiveStoreDomain', null);
          }
          window.location.href = '/';
        }, (err)=>{
          alert(err.response.data.message);
          setIsOnProcess(false);
        });
      });
    } catch (e){
      console.log(e);
      setIsOnProcess(false);
    }
  }
  return (
    <div className="container-xxl align-items-center">
      <div className="authentication-wrapper authentication-basic container-p-y login-form-center">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              <div className="app-brand justify-content-center">
                <a href="/login" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo">
                    <div className="logo-img text-center">
                        <img className="app-brand-logo-app" alt="" src="../gyfhint-logo.png"></img>
                    </div>
                  </span>
                </a>
              </div>
              <br/>
              <h5 className="mb-2">Welcome to GyftHint! 👋</h5>
              <p className="mb-4">Please sign-in to your account</p>

              <form id="formAuthentication" className="mb-3" onSubmit={handleLogin}>
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input
                    type="text"
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    className="form-control"
                    id="email"
                    name="email-username"
                    placeholder="Enter your email or username"
                    required
                    autoFocus />
                </div>
                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label" >Password</label>
                    <a href="auth-forgot-password-basic.html" className="d-none">
                      <small>Forgot Password?</small>
                    </a>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      type="password"
                      value={password}
                      onChange={(e)=>setPassword(e.target.value)}
                      id="password"
                      className="form-control"
                      name="password"
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password" required/>
                    <span className="input-group-text cursor-pointer"><i className="bx bx-hide"></i></span>
                  </div>
                </div>
                <div className="mb-3 d-none">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="remember-me" />
                    <label className="form-check-label" > Remember Me </label>
                  </div>
                </div>
                <br />
                <div className="mb-3">
                  { !isOnProcess?
                    <button className="btn btn-primary d-grid w-100" type="submit">Sign in</button>:
                    <button className="btn btn-primary d-grid w-100" disabled type="submit">Loading...</button>
                  }
                </div>
              </form>

              <p className="text-center d-none">
                <span>New on our platform?</span>
                <a href="/merchant-signup">
                  <span>Create an account</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login