import React, { useEffect, useState } from "react";
import axios from "../api/axios";
import DataTable from "react-data-table-component";
import direct_axios from 'axios';

const Promotions = () => {
  const [role, setRole] = useState(localStorage.getItem('profileRole'));
  const [defaultStatus, setDefaultStatus] = useState(false);
  const [canAdd, setCanAdd] = useState(0);
  const [canEdit, setCanEdit] = useState(0);
  const [canDelete, setCanDelete] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [moduleName, setModuleName] = useState('');
  const [moduleDescription, setModuleDescription] = useState('');
  const [moduleIcon, setModuleIcon] = useState('');
  const [len, setLen] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const [promoUuid, setPromoUuid] = useState('');
  const [label, setLabel] = useState('');
  const [promoType, setPromoType] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [promoValue, setPromoValue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [periodType, setPeriodType] = useState('infinite'); // Default to Infinite
  const [selectedRows, setSelectedRows] = useState([]);
  const stores = JSON.parse(localStorage.getItem('stores'));

  /* setting of forms */
  const [hints, setHints] = useState([]);
  const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
  const currentActiveStoreDomain = localStorage.getItem('currentActiveStoreDomain');
  const [storeId, setStoreId] = useState(currentActiveStoreId);
  /* setting of forms ends here */

  /* token and headers */
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }
  
  const userId = localStorage.getItem('userId');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  /* token and headers ends here */

  useEffect(() => {
    fetchModuleDetails();
    fetchTable();
    setIsLoading(true);
  }, [])

  const fetchModuleDetails = async () => {
    const module_nav_link = window.location.pathname;
    const data = {
      'uuid': userId,
      'module_nav_link': module_nav_link
    }
    try{
      await axios.post('/api/module-access-details', data, config).then(result =>{
        setModuleName(result.data.menu_access[0]['module_name']);
        setModuleDescription(result.data.menu_access[0]['module_description']);
        const icon = result.data.menu_access[0]['module_icon'];
        setModuleIcon(icon.replace('menu-icon tf-icons bx ', ''));
        setCanAdd(result.data.menu_access[0]['can_add']);
        setCanEdit(result.data.menu_access[0]['can_update']);
        setCanDelete(result.data.menu_access[0]['can_delete']);
      }, (err)=>{
        alert(err.response.data.message);
      });
    } catch (e){
      console.log(e);
    }
  }

  const fetchTable = async() => {
    let store_id = storeId === ''? null:storeId;
    let external_url = store_id===null?'https://gifthintbe.ebizonstaging.com/api/hint':'https://gifthintbe.ebizonstaging.com/api/hint?store_id='+store_id;
    try{
      await direct_axios.get(external_url, config ).then(result => {
        setHints(result.data.data);
        setLen(result.data.data.length);
        setIsLoading(false);
        console.log(result);
      })
    }catch(e){
      console.log(e);
      setIsLoading(false);
    }
  }

  const handleAddNew = (event) => {
    event.preventDefault();
    setDefaultStatus(true);
  }

  const handleClose = () => {
    setDefaultStatus(false);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedProduct((selectedProduct) => ({
      ...selectedProduct,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!label) {window.toastr.error('Label is Required', 'Oops!');
        return;
    }

    if (!promoType) {
      window.toastr.error('Promo Type is Required', 'Oops!');
      return;
    }

    const data = {
      'uuid':promoUuid,
      'store_id':storeId,
      'label':label,
      'promo_type':promoType,
      'promo_code':promoCode,
      'promo_value':promoValue===null?0:promoValue,
      'is_active':1,
      'start_date':startDate,
      'end_date':endDate,
      'created_by':userId,
      'store_domain': currentActiveStoreDomain,
      'promotions_relation_data':selectedRows
    };

    console.log(data);

    try{
        if(promoUuid===''){
          //Save to Customers
          await axios.post('/api/promotions', data, config).then(result=>{
            window.toastr.success(result.data.message, 'Good Job!');
            fetchTable();
            closeModal();
          }, (err)=>{
            console.log(err);
          });
        } /*else {
          //Update Customers
          await axios.put('/api/promotions/'+promoUuid, data, config).then(result=>{
            window.toastr.success(result.data.message, 'Good Job!');
            fetchTable();
          }, (err)=>{
            console.log(err);
          });
        }*/
      } catch(e){
        console.log(e);
      }

  }

  const handleRowSelected = (state) => {
    console.log(selectedRows);
    setSelectedRows(state.selectedRows);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRows(hints); // Select all rows
    } else {
      setSelectedRows([]); // Deselect all
    }
  };

  const handleRadioChange = (event) => {
      setPeriodType(event.target.value);
  };

  const closeModal = () => {
    const modalElement = document.getElementById('editProduct');
    modalElement.classList.remove('show'); // Hide modal
    modalElement.style.display = 'none'; // Set display to none
    document.body.classList.remove('modal-open'); // Remove modal-open class
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
        backdrop.remove(); // Remove backdrop
    }
  };

  const columns = [
    {
      name: <input type="checkbox" className="radio-large" onChange={handleSelectAll} />,
      cell: (row) => (
        <input
          className="radio-large"
          type="checkbox"
          checked={selectedRows.includes(row)}
          onChange={() => {
            const selected = selectedRows.includes(row)
              ? selectedRows.filter((r) => r !== row)
              : [...selectedRows, row];
            setSelectedRows(selected);
          }}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    { name: "STORE", selector: (row) => row.store, sortable: role.toLowerCase() === 'admin' },
    { name: "IMAGE", cell: (row) => <a data-bs-toggle="modal" data-bs-target="#editProduct"><img src={row.image} alt="product" style={{ width: 'auto', height: '100px' }} /></a> },
    { name: "PRODUCT NAME", selector: (row) => row.name, sortable: true, cell: (row) => <a data-bs-toggle="modal" data-bs-target="#editProduct" style={{ display: 'block', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}><b>{row.name}</b></a> },
    //{ name: "ACTION", cell: (row) => <div className="text-center"><div><a href="#" onClick={() => { const selected = selectedRows.includes(row) ? selectedRows.filter(r => r !== row) : [...selectedRows, row]; setSelectedRows(selected); }} className="btn btn-outline-warning btn-sm" data-bs-toggle="modal" data-bs-target="#editProduct">Promote</a></div></div> },
    { name: "ACTION", cell: (row) => <div className="text-center"><div><a href="#" onClick={() => { setSelectedRows([row]); }} className="btn btn-outline-warning btn-sm" data-bs-toggle="modal" data-bs-target="#editProduct">Promote</a></div></div> },
    { name: "SOURCE", selector: (row) => row.source, sortable: false, cell: (row) => row.source === 'User_Button' ? <label className="badge bg-label-warning w-100">Shopify Button</label> : <label className="badge bg-label-primary w-100 badge-long">Merchant Suggestion</label> },
    { name: "CATEGORY", selector: (row) => row.category, sortable: true },
    { name: "BRAND", selector: (row) => row.brand, sortable: true },
    { name: "PRICE", selector: (row) => row.final_price, sortable: true, cell: (row) => `$${row.final_price.toFixed(2)}` },
    { name: "EDIT", cell: (row) => canEdit === 1 ? <a href="#"><i className='bx bx-edit'></i></a> : '', omit: canEdit !== 1 }
  ];

  const columns_for_promotions = [
    { name: "IMAGE", cell: (row) => <a data-bs-toggle="modal" data-bs-target="#editProduct"><img src={row.image} alt="product" style={{ width: 'auto', height: '100px' }} /></a> },
    { name: "PRODUCT NAME", selector: (row) => row.name, sortable: true },
  ];

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="main-kpis">
        <div className="row">
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className="menu-icon-page tf-icons bx bx-gift"></span></div>
                  <div className="details"><h2>Total Promotions</h2><p><b><span className="increase">3,980</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className='menu-icon-page bx bx-line-chart' ></span></div>
                  <div className="details"><h2>Total GMV</h2><p><b><span className="increase">$258,000.00</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><b><i className="menu-icon-page tf-icons bx bx-chart"></i></b></div>
                  <div className="details"><h2>GMV Growth</h2><p><b><span className="increase">32%</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className='menu-icon-page bx bx-award' ></span></div>
                  <div className="details"><h2>Top Category</h2><p><b><span className="increase">Clothing</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><i className="menu-icon-page tf-icons bx bxs-award"></i></div>
                  <div className="details"><h2>Top Product</h2><p><b><span className="increase">Camber 305 Max...</span></b></p></div>
              </div>
          </div>
        </div> 
      </div>
      {role.toLowerCase() === 'admin'&&
      <div className="main-kpis">
          <select className="form-select border border-default rounded w-20 ms-1">
            <option value="All">All Stores</option>
            {stores.map((store, index) => (
              <option key={index} value={store.uuid}>
                {store.store_name}
              </option>
            ))}
          </select>
      </div>
      }
      <div className="main-kpis d-none">
          <div className="input-group border border-default rounded w-30">
            <span className="input-group-text bg-white border-0">
              <i className="bx bx-search fs-4 lh-0"></i>
            </span>
            <input
              type="text"
              className="form-control border-0 shadow-none"
              placeholder="Search Products..."
              aria-label="Search Products..." 
            />
          </div>
          <select className="form-select border border-default rounded w-20 ms-1">
            <option value="all">All Filters</option>
            <option value="1">Hot & Fast-Moving Products</option>
            <option value="2">High Quality Hints</option>
            <option value="3">Slow-Moving Product</option>
            <option value="4">By Product Category</option>
            <option value="5">By Customer Segment (loyalty program)</option>
          </select>
          <a className="btn btn-outline-primary ms-1 d-none">
            <i className="bx bx-filter"></i>
            Sort
          </a>
      </div>
      <div className="row mb-12">
          <div id="table-list-container" className="col-md-12">
          <div className="card">
                <h5 className="card-header">
                  <span className='tf-icons bx bxs-megaphone'></span> {role.toLowerCase() === 'admin' ? 'Promotions' : 'Promotions'}
                  {canAdd===1 && <button type="submit" className="btn btn-primary btn-sm ms-2" onClick={handleAddNew}> Add &nbsp; <i className="bx bxs-plus-circle"></i></button>}
                  {selectedRows.length > 0 &&
                    <a href="" className="btn btn-outline-warning btn-sm ms-2" data-bs-toggle="modal" data-bs-target="#editProduct"> Promote</a>
                  }
                </h5>
                {isLoading && 
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Store</th>
                        <th>Image</th>
                        <th>Product Name</th>
                        <th>Action</th>
                        <th>Source</th>
                        <th>Category</th>
                        <th>Brand</th>
                        <th>Price</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                      <tr><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td></tr>
                      <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                    </tbody>
                  </table>
                </div>}
                {!isLoading && len ===0 &&
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Store</th>
                        <th>Image</th>
                        <th>Product Name</th>
                        <th>Action</th>
                        <th>Source</th>
                        <th>Category</th>
                        <th>QTY</th>
                        <th>Price</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={9}>No records found.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>}
                <div className="table-responsive text-nowrap">
                  <DataTable
                    columns={columns}
                    data={hints}
                    noDataComponent=""
                    pagination
                    highlightOnHover
                    onRowSelected={handleRowSelected}
                  />
                </div>
              </div>
          </div>      
      </div>

      <div className="modal fade" id="editProduct" tabindex="-1" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <div className="mb-6">
                <h4 className="mb-2">Promotion Details</h4>
              </div>
              <hr />
              <form className="row g-6" onsubmit="false">
                <div className="col-12 col-md-4">
                </div>
                <div className="col-12 col-md-8">
                <label className="" for="modalEditUserFirstName"><b>List of Products</b></label>
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label" for="modalEditUserFirstName">Label:</label>
                  <input type="text" id="label" name="label" className="form-control" placeholder="Enter Label" defaultValue={label} onChange={(e)=>setLabel(e.target.value)}/>
                  <label className="form-label" for="modalEditUserFirstName">Promo Code:</label>
                  <input type="text" id="label" name="label" className="form-control" placeholder="Enter Promo Code" defaultValue={promoCode} onChange={(e)=>setPromoCode(e.target.value)}/>
                  <label className="form-label" for="modalEditUserLastName">Promo Type:</label>
                  <select className="form-control" name="promoType" id="promoType" value={promoType} onChange={(e) => setPromoType(e.target.value)}>
                    <option value="">Enter Promo Type</option>
                    <option value="Discount by Value">Discount by Value</option>
                    <option value="Discount by Percentage">Discount by Percentage</option>
                    <option value="Free Shipping">Free Shipping</option>
                    <option value="Buy 1 Get 1">Buy 1 Get 1</option>
                  </select>

                  {(promoType === 'Discount by Value' || promoType === 'Discount by Percentage') && (
                      <div>
                          <label className="form-label" for="modalEditUserLastName">Promo Value:</label>
                          <input type="number" name="promoValue" className="form-control" id="promoValue" placeholder="Enter promo value" onChange={(e)=>setPromoValue(e.target.value)}/>
                      </div>
                  )}

                  <label className="form-label mb-2" for="modalEditUserLastName">Promo Period:</label>
                  <br />
                  <input type="radio" id="infinite" name="period_type" className="form-check-input ms-2" value="infinite" onChange={handleRadioChange} defaultChecked></input> 
                  <label class="form-check-label ms-2" for="defaultRadio1"> <b>Indefinite</b> </label>
                  <br />
                  <input type="radio" id="others" name="period_type" className="form-check-input ms-2" value="others" placeholder="" onChange={handleRadioChange} ></input>
                  <label class="form-check-label ms-2" for="defaultRadio1"> <b>Select Period</b> </label>

                  {periodType === 'others' && (
                      <div className="mt-3">
                          <label className="form-label" htmlFor="dateFrom">Date From:</label>
                          <input
                              type="date"
                              id="start_date"
                              className="form-control"
                              onChange={(e)=>setStartDate(e.target.value)}
                          />
                          <label className="form-label mt-2" htmlFor="dateTo">Date To:</label>
                          <input
                              type="date"
                              id="end_date"
                              className="form-control"
                              onChange={(e)=>setEndDate(e.target.value)}
                          />
                      </div>
                  )}

                </div>
                <div className="col-12 col-md-8">
                    {selectedRows.length===0?
                    <div className="table-responsive text-nowrap">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Product Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan={2}>No product selected.</td>
                          </tr>
                        </tbody>
                      </table>
                  </div>:
                  <div className="table-responsive table-bordered">
                    <DataTable
                      columns={columns_for_promotions}
                      data={selectedRows}
                      noDataComponent=""
                      pagination
                      paginationPerPage={5}
                    />
                  </div>
                  }
                </div>
                <div className="col-12">
                  <hr />
                  {!isSaving?<button type="submit" className="btn btn-warning me-3" onClick={handleSubmit}>Promote</button>:<button type="submit" disabled className="btn btn-primary me-3" onClick={handleSubmit}>Saving...</button>}
                  <button type="reset" className="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Promotions;
