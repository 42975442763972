import React, { useEffect, useState } from "react";
import axios from "../api/axios";
import DataTable from "react-data-table-component";
import direct_axios from 'axios';

const CuratedHints = () => {
  const [role, setRole] = useState(localStorage.getItem('profileRole'));
  const [modal, setModal] = useState('hide');
  const [defaultStatus, setDefaultStatus] = useState(false);
  const [modalName, setModalName] = useState('');
  const [canAdd, setCanAdd] = useState(0);
  const [canEdit, setCanEdit] = useState(0);
  const [canDelete, setCanDelete] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [moduleName, setModuleName] = useState('');
  const [moduleDescription, setModuleDescription] = useState('');
  const [moduleIcon, setModuleIcon] = useState('');
  const [len, setLen] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const stores = JSON.parse(localStorage.getItem('stores'));
  const [selectedStore, setSelectedStore] = useState('');

  /* setting of forms */
  const [hints, setHints] = useState([]);
  const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
  const [storeId, setStoreId] = useState(currentActiveStoreId);
  /* setting of forms ends here */

  /* token and headers */
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }
  
  const userId = localStorage.getItem('userId');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  /* token and headers ends here */

  useEffect(() => {
    fetchModuleDetails();
    fetchTable();
    setIsLoading(true);
  }, [])

  const fetchModuleDetails = async () => {
    const module_nav_link = window.location.pathname;
    const data = {
      'uuid': userId,
      'module_nav_link': module_nav_link
    }
    try{
      await axios.post('/api/module-access-details', data, config).then(result =>{
        setModuleName(result.data.menu_access[0]['module_name']);
        setModuleDescription(result.data.menu_access[0]['module_description']);
        const icon = result.data.menu_access[0]['module_icon'];
        setModuleIcon(icon.replace('menu-icon tf-icons bx ', ''));
        setCanAdd(result.data.menu_access[0]['can_add']);
        setCanEdit(result.data.menu_access[0]['can_update']);
        setCanDelete(result.data.menu_access[0]['can_delete']);
      }, (err)=>{
        alert(err.response.data.message);
      });
    } catch (e){
      console.log(e);
    }
  }

  const fetchTable = async() => {
    setIsLoading(true);
    setHints([]);
    let store_id = storeId === ''? null:storeId;
    let external_url = store_id===null || store_id===''?'https://gifthintbe.ebizonstaging.com/api/hint':'https://gifthintbe.ebizonstaging.com/api/hint?store_id='+store_id;
    try{
      await direct_axios.get(external_url, config ).then(result => {
        setHints(result.data.data);
        setLen(result.data.data.length);
        setIsLoading(false);
        console.log(result);
      })
    }catch(e){
      console.log(e);
      setIsLoading(false);
    }
  }

  const fetchProductDetails = async (uuid) => {
    try {
      await direct_axios.get('https://gyfthint-dev.uc.r.appspot.com/hint/' + uuid, config)
        .then(result => {
          setSelectedProduct(result.data.data); // Update the state
          console.log(result.data.data);
        });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  
  const handlePurchaseProductClick = async (uuid) => {
    try {
      await direct_axios.get('https://gyfthint-dev.uc.r.appspot.com/hint/' + uuid, config)
        .then(result => {
          const url = result.data.data.purchase_url;
          window.open(url, '_blank');  // Open the URL in a new tab
        });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const handleAddNew = (event) => {
    event.preventDefault();
    setModal('show');
    setDefaultStatus(true);
    setModalName('Add New Products');
  }

  const handleClose = () => {
    setModal('hide');
    setDefaultStatus(false);
    setModalName('');
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedProduct((selectedProduct) => ({
      ...selectedProduct,
      [name]: value,
    }));
  };

  const handleSubmit = async(event) => {
    event.preventDefault();

    setIsSaving(true);
    console.log(selectedProduct);

      try {
        const result = await direct_axios.put(
          'https://gyfthint-dev.uc.r.appspot.com/hint/' + selectedProduct.id, selectedProduct,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        setIsSaving(false);
        fetchTable();
        window.toastr.success("Product successfully updated!", 'Good Job!');
      } catch (error) {
        console.error("Error making PUT request", error);
        setIsSaving(false);
        window.toastr.error("Product update failed!", 'Oops!');
      }
  }

  const handleChange = async (event) => {
    const storeId = event.target.value;
    if(storeId==='All'){
      fetchTable();
    } else {
      setIsLoading(true);
      setSelectedStore(storeId);
      setHints([]);
      
      if (storeId) {
        try {
          const response = await direct_axios.get('https://gifthintbe.ebizonstaging.com/api/hint?store_id='+storeId); // Replace with your API endpoint
          const response_data = await response.data;
          setHints(response_data.data);
          setLen(response_data.data.length);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching store data:', error);
          setIsLoading(false);
        }
      }
    }
  };

  const columns = [
    { name: "STORE", selector: (row) => row.store, sortable: role.toLowerCase() === 'admin' },
    { name: "IMAGE", cell: (row) => <a onClick={() => fetchProductDetails(row.id)} data-bs-toggle="modal" data-bs-target="#editProduct"><img src={row.image} alt="product" style={{ width: 'auto', height: '100px' }} /></a> },
    { name: "PRODUCT NAME", selector: (row) => row.name, sortable: true, cell: (row) => <a onClick={() => fetchProductDetails(row.id)} data-bs-toggle="modal" data-bs-target="#editProduct" style={{ display: 'block', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}><b>{row.name}</b></a> },
    { name: "PRODUCT URL", cell: (row) => (<div className="text-center"><div><a className="btn w-100 btn-outline-primary btn-sm mb-2" href={row.url.includes('https://') ? row.url : 'https://' + row.url} target="_blank" rel="noreferrer">View Product</a></div><div><a href="#" className="btn btn-outline-primary btn-sm mb-2 w-100" onClick={() => handlePurchaseProductClick(row.id)} rel="noreferrer">Purchase Product</a></div></div>) },
    { name: "SOURCE", selector: (row) => row.source, sortable: false, cell: (row) => row.source === 'User_Button' ? <label className="badge bg-label-warning w-100">Shopify Button</label> : <label className="badge bg-label-primary w-100 badge-long">Merchant Suggestion</label> },
    { name: "CATEGORY", selector: (row) => row.category, sortable: true },
    { name: "BRAND", selector: (row) => row.brand, sortable: true },
    { name: "PRICE", selector: (row) => row.final_price, sortable: true, cell: (row) => `$${row.final_price.toFixed(2)}` },
    { name: "EDIT", cell: (row) => canEdit === 1 ? <a href="#"><i className='bx bx-edit'></i></a> : '', omit: canEdit !== 1 }
  ];

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="main-kpis">
        <div className="row">
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className="menu-icon-page tf-icons bx bx-gift"></span></div>
                  <div className="details"><h2>Total Products</h2><p><b><span className="increase">3,980</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className='menu-icon-page bx bx-line-chart' ></span></div>
                  <div className="details"><h2>Total GMV</h2><p><b><span className="increase">$258,000.00</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><b><i className="menu-icon-page tf-icons bx bx-chart"></i></b></div>
                  <div className="details"><h2>GMV Growth</h2><p><b><span className="increase">32%</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className='menu-icon-page bx bx-award' ></span></div>
                  <div className="details"><h2>Top Category</h2><p><b><span className="increase">Clothing</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><i className="menu-icon-page tf-icons bx bxs-award"></i></div>
                  <div className="details"><h2>Top Product</h2><p><b><span className="increase">Camber 305 Max...</span></b></p></div>
              </div>
          </div>
        </div> 
      </div>
      {role.toLowerCase() === 'admin'&&
      <div className="main-kpis">
          <select className="form-select border border-default rounded w-20 ms-1" onChange={handleChange} value={selectedStore}>
            <option value="All">All Stores</option>
            {stores.map((store, index) => (
              <option key={index} value={store.uuid}>
                {store.store_name}
              </option>
            ))}
          </select>
      </div>
      }
      <div className="row mb-12">
          <div id="table-list-container" className="col-md-12">
          <div className="card">
                <h5 className="card-header">
                  <span className='bx bx-gift'></span> {role.toLowerCase() === 'admin' ? 'Products' : 'Products'}
                  {canAdd===1 && <button type="submit" className="btn btn-primary btn-sm ms-2" onClick={handleAddNew}> Add &nbsp; <i className="bx bxs-plus-circle"></i></button>}
                </h5>
                {isLoading && 
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Store</th>
                        <th>Image</th>
                        <th>Product Name</th>
                        <th>Product Url</th>
                        <th>Source</th>
                        <th>Category</th>
                        <th>Brand</th>
                        <th>Price</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                      <tr><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td></tr>
                      <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                    </tbody>
                  </table>
                </div>}
                {!isLoading && len ===0 &&
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Store</th>
                        <th>Image</th>
                        <th>Product Name</th>
                        <th>Product Url</th>
                        <th>Source</th>
                        <th>Category</th>
                        <th>QTY</th>
                        <th>Price</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={9}>No records found.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>}
                <div className="table-responsive text-nowrap">
                  <DataTable
                    columns={columns}
                    data={hints}
                    noDataComponent=""
                    pagination
                    highlightOnHover
                  />
                </div>
              </div>
          </div>      
      </div>

      <div className="modal fade" id="editProduct" tabindex="-1" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-simple modal-edit-user">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <div className="mb-6">
                <h4 className="mb-2">Product Details</h4>
              </div>
              <hr />
              <form className="row g-6" onsubmit="false">
                <div className="col-12 col-md-4">
                  <label className="" for="modalEditUserFirstName"><b>Item Details</b><br /><br /></label>
                </div>
                <div className="col-12 col-md-4">
                <label className="" for="modalEditUserFirstName"><b>Pricing Details</b></label>
                </div>
                <div className="col-12 col-md-4">
                <label className="" for="modalEditUserFirstName"><b>Description</b></label>
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label" for="modalEditUserFirstName">Hint Id:</label>
                  <p>{selectedProduct.id}</p>
                  <label className="form-label" for="modalEditUserFirstName">Name:</label>
                  <input type="text" id="name" name="name" className="form-control" placeholder="" defaultValue={selectedProduct.name} onChange={handleInputChange}/>
                  <label className="form-label" for="modalEditUserLastName">Store:</label>
                  <input type="text" id="store" name="store" className="form-control" placeholder="" defaultValue={selectedProduct.store}  onChange={handleInputChange}/>
                  <label className="form-label" for="modalEditUserLastName">Brand:</label>
                  <input type="text" id="brand" name="brand" className="form-control" placeholder="" defaultValue={selectedProduct.brand}  onChange={handleInputChange}/>
                  <label className="form-label" for="modalEditUserLastName">URL:</label>
                  <input type="text" id="url" name="url" className="form-control" placeholder="" defaultValue={selectedProduct.url} onChange={handleInputChange} />
                  <label className="form-label" for="modalEditUserLastName">Purchase URL:</label>
                  <input type="text" id="purchase_url" name="purchase_url" className="form-control" placeholder="" defaultValue={selectedProduct.purchase_url}  onChange={handleInputChange}/>
                  <label className="form-label" for="modalEditUserLastName">Category:</label>
                  <input type="text" id="category" name="category" className="form-control" placeholder="" defaultValue={selectedProduct.category} onChange={handleInputChange} />
                </div>

                <div className="col-12 col-md-4">
                  <label className="form-label" htmlFor="modalEditUserLastName">Gyfthint Price 1:</label>
                  <input
                    type="text"
                    id="modalEditUserLastName"
                    name="modalEditUserLastName"
                    className="form-control"
                    placeholder=""
                    disabled
                    value={selectedProduct.price ? selectedProduct.price.toFixed(2) : '0.00'}
                  />

                  <label className="form-label" htmlFor="modalEditUserShipping">S+H:</label>
                  <input
                    type="text"
                    id="modalEditUserShipping"
                    name="modalEditUserShipping"
                    className="form-control"
                    placeholder=""
                    disabled
                    value={selectedProduct.shipping_and_handling ? selectedProduct.shipping_and_handling.toFixed(2) : '0.00'}
                  />

                  <label className="form-label" htmlFor="modalEditUserTax">Tax:</label>
                  <input
                    type="text"
                    id="modalEditUserTax"
                    name="modalEditUserTax"
                    className="form-control"
                    placeholder=""
                    disabled
                    value={selectedProduct.sales_tax ? selectedProduct.sales_tax.toFixed(2) : '0.00'}
                  />

                  <label className="form-label" htmlFor="modalEditUserFinalPrice">Final Price:</label>
                  <input
                    type="text"
                    id="modalEditUserFinalPrice"
                    name="modalEditUserFinalPrice"
                    className="form-control"
                    placeholder=""
                    disabled
                    value={selectedProduct.final_price ? selectedProduct.final_price.toFixed(2) : '0.00'}
                  />
                </div>


                <div className="col-12 col-md-4">
                  <textarea cols="20" className="form-control" placeholder="example@domain.com" value={selectedProduct.description} readOnly style={{ height: '100%' }} disabled></textarea>
                </div>
                {role.toLowerCase()==='admin' && 
                  <div className="col-12">
                    <hr />
                    {!isSaving?<button type="submit" className="btn btn-primary me-3" onClick={handleSubmit}>Update</button>:<button type="submit" disabled className="btn btn-primary me-3" onClick={handleSubmit}>Saving...</button>}
                    <button type="reset" className="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                  </div>
                }
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default CuratedHints;
